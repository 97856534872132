<template>
  <div class="prestations">
    <div class="vid">
      <video id="video" src="../assets/background2.mp4" autoplay="true" preload="auto" loop muted></video>
    </div>

    <h2><span class="orange">Pres</span>tations</h2>

    <div class="main">

        <div class="masonry">
          <div class="card item" v-for="prestation in prestations" :key="prestation.id" :onmouseover=prestation.hoverColor onmouseout="this.style.backgroundColor='';" >
            <div :style=prestation.color>
              <i :class=prestation.icone></i>
            </div>
            <h5 class="cardTitle">{{prestation.name}}</h5>
            <div class="description">{{prestation.description}}</div>
            <p class="prix">{{prestation.prix}}</p>
          </div>
        </div>
        
    </div>
  </div>
</template>

<style scoped>

.masonry {
  margin: 55px auto 0 auto;
  max-width: 80%;
  column-gap: 1.5em;
  padding-bottom: 50px;
  overflow-x: visible;
}

.cardTitle{
  font-family: "Jura";
  font-size: 20px;
  margin-bottom: 20px;
}

.description{
  font-family: "Jura";
  font-size: 16px;
}

.prix{
  font-family: "Jura";
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
}

/* The Masonry Brick */

.item {
  width: 90%;
  margin: 0 0 1.5em;
  border: solid rgb(180, 177, 177) 2px;
  transition : 0.5s;
  display: inline-block;
  text-align: center;
  padding: 20px;
  color: white;
}

.item svg{
  font-size:60px;
  transition : 1s;
  margin: 10px 0;
}

.item:hover svg{
  transform: scale(1.2);
}

/* Masonry on large screens */
@media only screen and (min-width: 1377px) {
  .masonry {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1376px) and (min-width: 768px) {
  .masonry {
    column-count: 2;
  }
}
</style>

<script>
export default {
  name: 'Prestations',
  data(){

        return{
          prestations : {
            "forfaitDeBase": {
              "name": "Forfait de Base",
              "description": "Déplacement à votre Domicile (15 km autour de Tour en Bessin ) + 1h30 d'intervention.",
              "prix":"59.00€",
              "icone":"fas fa-car-side",
              "color":"color:#005FD5",
              "hoverColor":"this.style.backgroundColor='rgba(0, 95, 213, 0.4)';",
            },

            "saveEtRecup": {
              "name": "Sauvegarde et Récupération des données",
              "description": "Récupération de données personnelles inaccessibles suite à des défaillances du système d’exploitation.",
              "prix":"69.00€",
              "icone":"far fa-save",
              "color":"color:#FF8100",
              "hoverColor":"this.style.backgroundColor='rgba(255, 129, 0, 0.4)';",
            },

            "installEtDepan": {
              "name": "Installation /Dépannage Internet",
              "description": "",
              "prix":"",
              "icone":"fas fa-wifi",
              "color":"color:#4FC86C",
              "hoverColor":"this.style.backgroundColor='rgba(79, 200, 108, 0.4)';",
            },
            
            "formations": {
              "name": "Formations Windows, Word, Excel, Internet, sécurité informatique, retouche photo ",
              "description": "",
              "prix":"",
              "icone":"fas fa-chalkboard-teacher",
              "color":"color:#9867C8",
              "hoverColor":"this.style.backgroundColor='rgba(152, 103, 200, 0.4)';",
            },

            "securSys": {
              "name": "Sécurisation du Système",
              "description": "",
              "prix":"",
              "icone":"fas fa-shield-alt",
              "color":"color:#38A5D8",
              "hoverColor":"this.style.backgroundColor='rgba(56, 165, 216, 0.4)';",
            },

            "installLog": {
              "name": "Installation Logiciels",
              "description": "",
              "prix":"",
              "icone":"fas fa-download",
              "color":"color:#607D8B",
              "hoverColor":"this.style.backgroundColor='rgba(96, 125, 139, 0.4)';",
            },

            "depanMateriel": {
              "name": "Dépannage Panne Matérielle",
              "description": "",
              "prix":"",
              "icone":"fas fa-wrench",
              "color":"color:#7A5648",
              "hoverColor":"this.style.backgroundColor='rgba(122, 86, 72, 0.4)';",
            },

            "master": {
              "name": "Réinstallation du Système d'Exploitation",
              "description": `Crash du système, PC instable, trop lent ou infecté. Le forfait comprend :
              - Déplacement (jusqu'à 15 km autour de Tour en Bessin)
              - Réinstallation de votre système + mises à jour.
              - Installation de votre AntiVirus.
              - Installation de vos Périphériques.
              - Installation de vos Utilitaires.
              - Connexion de votre PC à Internet.`,
              "prix":"94.00€",
              "icone":"fas fa-redo-alt",
              "color":"color:#F44336",
              "hoverColor":"this.style.backgroundColor='rgba(244, 67, 54, 0.4)';",
            },

            "installPeriphS": {
              "name": "Installation de périphériques simples (disques durs, graveur de DVD…)",
              "description": "",
              "prix":"",
              "icone":"fas fa-compact-disc",
              "color":"color:#EA1E63",
              "hoverColor":"this.style.backgroundColor='rgba(234, 30, 99, 0.4)';",
            },

            "changePieces": {
              "name": "Changement de pièces défectueuses",
              "description": "",
              "prix":"",
              "icone":"fas fa-screwdriver",
              "color":"color:#673AB7",
              "hoverColor":"this.style.backgroundColor='rgba(103, 58, 183, 0.4)';",
            },

            "installPeriphE": {
              "name": "Installation de périphériques évolués (imprimantes, scanners, cartes graphique, PCI…)",
              "description": "",
              "prix":"",
              "icone":"fas fa-print",
              "color":"color:#2C3E50",
              "hoverColor":"this.style.backgroundColor='rgba(44, 62, 80, 0.2)';",
            },

            " desinfection": {
              "name": "Désinfection Virus et Malwares",
              "description": "Suppression des Virus et Logiciels Indésirables.",
              "prix":"",
              "icone":"fas fa-shield-virus",
              "color":"color:#00a88e",
              "hoverColor":"this.style.backgroundColor='rgba(0,168,142, 0.2)';",
            },
          }
        }

      },
      mounted () {
        window.scrollTo(0, 0)
      }
}
</script>